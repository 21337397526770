'use strict';

import {TweenMax} from 'gsap/TweenMaxBase';
import {TimelineMax} from 'gsap/TimelineMax';
import ScrollToPlugin from 'gsap/ScrollToPlugin'; // スムーススクロール用プラグイン
const plugins = [ScrollToPlugin]; // eslint-disable-line no-unused-vars
import ScrollMagic from 'scrollmagic';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';

export default () => {
  let controller = new ScrollMagic.Controller();

  /* ページトップへ
  ---------------------------------------- */
  const pageTopElm = document.querySelector('.js-pageTop');

  if(pageTopElm) {
    pageTopElm.addEventListener('click', () => {
      TweenMax.to(window, 0.64, {scrollTo:{y:'body', autoKill: false}});
    });
  }

  /* スライドイン
  ---------------------------------------- */
  const slideSetFunc = (list) => {
    const posName= list[0].className;
    let pos;
    let property = {opacity: 0};
    let offset = 100;

    pos = posName.match(/js-slide.+/)[0].slice(-1);

    switch(pos) {
    case 'T':
      property['y'] = -40;
      offset = offset + 40;
      break;
    case 'R':
      property['x'] = 40;
      break;
    case 'B':
      property['y'] = 40;
      offset = offset - 40;
      break;
    case 'L':
      property['x'] = -40;
      break;
    default:
    }
    for(let i = 0; i < list.length; i++) {
      let slideTw = TweenMax.from(list[i], 0.64, property);
      new ScrollMagic.Scene({
        offset: offset,
        triggerElement: list[i],
        triggerHook: 'onEnter',
        reverse: false
      })
        .setTween(slideTw)
        // .addIndicators({name: 'slideTw'})
        .addTo(controller);
    }
  };

  // 上から
  if(document.querySelector('.js-slideT')) {
    slideSetFunc(document.querySelectorAll('.js-slideT'));
  }

  // 右から
  if(document.querySelector('.js-slideR')) {
    slideSetFunc(document.querySelectorAll('.js-slideR'));
  }

  // 下から
  if(document.querySelector('.js-slideB')) {
    slideSetFunc(document.querySelectorAll('.js-slideB'));
  }

  // 左から
  if(document.querySelector('.js-slideL')) {
    slideSetFunc(document.querySelectorAll('.js-slideL'));
  }

  /* 投稿などのリスト
  ---------------------------------------- */
  const fadeList = document.querySelectorAll('.js-fadeList');
  let listTween;

  for(let i = 0; i < fadeList.length; i++) {
    listTween = TweenMax.staggerFrom(fadeList[i].children, 0.64, {
      opacity: 0,
      y: 20,
    }, 0.24);

    new ScrollMagic.Scene({
      triggerElement: fadeList[i],
      offset: -120,
      reverse: false
    })
      .setTween(listTween)
      // .addIndicators({name: `fadeList${i}`})
      .addTo(controller);
  }

  /* 順次スライド
  ---------------------------------------- */
  if(document.querySelector('.js-orderSlide') || document.querySelector('.js-orderSlideBox')) {
    const orderSlide = document.querySelectorAll('.js-orderSlide, .js-orderSlideBox');
    let orderTween;

    for(let i = 0; i < orderSlide.length; i++) {
      if(orderSlide[i].classList.contains('js-orderSlide')) {
        orderTween = TweenMax.staggerFromTo(orderSlide[i].children, 0.54, {opacity:0, x: 40}, {opacity: 1, x: 0}, 0.24);
      } else {
        orderTween = new TimelineMax().from(orderSlide[i], 0.54, {opacity: 0, x: 40}).staggerFrom(orderSlide[i].children, 0.54, {opacity: 0, x: 40}, 0.24);
      }

      new ScrollMagic.Scene({
        triggerElement: orderSlide[i],
        reverse: false
      })
        .setTween(orderTween)
        // .addIndicators({name: 'about'})
        .addTo(controller);
    }
  }

};
