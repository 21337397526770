'use strict';

const navBtnElm = document.querySelector('.js-headNavBtn');
const navElm = document.querySelector('.js-headNav');
const navBtnBarElm = document.querySelector('.js-headNavBtnBar');
const navBtnElm2 = document.querySelector('.js-headNavBtn2');
const navElm2 = document.querySelector('.js-headNav2');
//const navBtnBarElm2 = document.querySelector('.js-headNavBtnBar2');
const navBtnElm3 = document.querySelector('.js-headNavBtn3');
const navElm3 = document.querySelector('.js-headNav3');
//const navBtnBarElm3 = document.querySelector('.js-headNavBtnBar3');
const navAllElm = document.querySelector('.js-headNavAll');

/*
const navProdBtnElm = document.querySelector('.js-headNavProdBtn');
const navProdElm = document.querySelector('.js-headNavProd');
const navAllElm = document.querySelector('.js-headNavProdAll');
*/

export default () => {
  navBtnElm.addEventListener('click', () => {
    if(!navElm.classList.contains('is-show')) {
      navElm.classList.add('is-show');
      navBtnElm.classList.add('is-close');
      navBtnBarElm.classList.add('is-close');
    } else {
      navElm.classList.remove('is-show');
      navBtnElm.classList.remove('is-close');
      navBtnBarElm.classList.remove('is-close');
      navElm2.classList.remove('is-show');
      navElm3.classList.remove('is-show');
      navAllElm.classList.remove('is-hidden');
    }
  });

  navBtnElm2.addEventListener('click', () => {
    if(!navElm2.classList.contains('is-show')) {
      navAllElm.classList.add('is-hidden');
      navElm2.classList.add('is-show');
      navBtnElm2.classList.add('is-close');
      navBtnBarElm.classList.add('is-close');
    } else {
      navElm2.classList.remove('is-show');
      navBtnElm2.classList.remove('is-close');
      navBtnBarElm.classList.remove('is-close');
    }
  });

  navBtnElm3.addEventListener('click', () => {
    if(!navElm3.classList.contains('is-show')) {
      navAllElm.classList.add('is-hidden');
      navElm3.classList.add('is-show');
      navBtnElm3.classList.add('is-close');
      navBtnBarElm.classList.add('is-close');
    } else {
      navElm3.classList.remove('is-show');
      navBtnElm3.classList.remove('is-close');
      navBtnBarElm.classList.remove('is-close');
    }
  });

/*
  navProdBtnElm.addEventListener('click', () => {
    if(!navProdElm.classList.contains('is-show')) {
      navAllElm.classList.add('is-hidden');
      navProdElm.classList.add('is-show');
      navProdBtnElm.classList.add('is-close');
    } else {
      navProdElm.classList.remove('is-show');
      navProdBtnElm.classList.remove('is-close');
    }
  });
*/
};
