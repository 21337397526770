'use strict';
import scrollAnime from './utils/scroll-anime.js';
import swiper from './utils/swiper.js';
import headerMenu from './utils/header-menu.js';
import moreBtn from './utils/more.js';
import luxy from './utils/luxy.js';
import gifReload from './utils/gif-reload.js';

const preloadElm = document.querySelector('#js-preload');
const loadElm = document.querySelector('#js-loading');

const commonFunc = () => {
  headerMenu();
  scrollAnime();
  swiper();
  moreBtn();
  luxy();
  gifReload();
};

if(loadElm) {
  setTimeout(() => { // 擬似ローディングのためだけ
    loadElm.classList.add('is-load');
    loadElm.addEventListener('transitionend', () => {
      loadElm.classList.add('is-loaded');
    });
    commonFunc();
  }, 1500);
} else {
  commonFunc();
}

window.onload = () => {
  preloadElm.classList.remove('is-stopTransition');
};
