'use strict';
import $ from 'jquery';

export default () => {
  const moreNum = 5;
  $('.js-prodItem:nth-child(n + ' + (moreNum + 1) + ')').addClass('is-hidden');
  $('.js-moreBtn').on('click', function() {
    $('.js-prodItem.is-hidden').slice(0, moreNum).removeClass('is-hidden');
    if ($('.js-prodItem.is-hidden').length == 0) {
      $('.js-moreBtn').fadeOut();
    }
  });
};
