'use strict';
import $ from 'jquery';

export default () => {
  $(document).ready( function(){
    let timestamp = new Date().getTime();
    $('#animation').attr('src', '/assets/img/common/loading_logo.gif' + '?' + timestamp);
  });

};
