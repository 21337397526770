'use strict';
import $ from 'jquery';
import Swiper from 'swiper';

// eslint-disable-line
export default () => {
  $(document).ready(function () {
    // 商品詳細ページ（サムネイル連動）
    const itemSlide = new Swiper('.js-itemSlide', {
      loop: false,
      effect: 'fade',
      slidesPerView: 'auto',
      navigation: {
        nextEl: '.js-itemSlideNext',
        prevEl: '.js-itemSlidePrev',
      },
      onInit: function (swiper) {
        $('.c-itemSlideThumbList__item').eq(swiper.realIndex).addClass('is-active');
        // slideが2枚以上でprev,nextボタンを表示
        if (swiper.slides.length <= 1) {
          swiper.destroy(false, true);
        } else if (swiper.slides.length >= 1) {
          $(swiper.prevButton).show();
          $(swiper.nextButton).show();
        }
      },
      onTransitionEnd: function (swiper) {
        $('.js-itemSlideThumbList__item').removeClass('is-active');
        $('.js-itemSlideThumbList__2item').eq(swiper.realIndex).addClass('is-active');
      }
    });

    new Swiper('.js-itemSlide2', {
      loop: false,
      slidesPerView: 7,
      spaceBetween: 20,
      navigation: {
        nextEl: '.js-itemSlideNext2',
        prevEl: '.js-itemSlidePrev2'
      },
      breakpoints: {
        // ウィンドウサイズが320px以下
        320: {
          slidesPerView: 4,
          spaceBetween: 10
        },
        // ウィンドウサイズが480px以下
        768: {
          slidesPerView: 6,
          spaceBetween: 20
        },
        // ウィンドウサイズが640px以下
        960: {
          slidesPerView: 4,
          spaceBetween: 20
        }
      }
    });

    $('.js-itemSlideThumbList__item').on('click', function () {
      itemSlide.slideTo($(this).index());
    });
  });
};

